export default {
  
  boxOffice: {
    name: null,
    id: null,
  },
  
  event: {
    name: null,
    id: null,
  },

  order: {
    items: [],
  },

  // need to actually manipulate the array otherwise watchers don't see what is happening
  resetItems() {
    while(this.order.items.length > 0) {
      this.order.items.pop();
    }
  },

  store(boxOffice, event) {
    this.boxOffice.id = boxOffice.id;
    this.boxOffice.name = boxOffice.name;
    if (event) {
      this.event.id = event.id;
      this.event.name = event.name;
    } else {
      this.event.id = null;
      this.event.name = null;
    }
  }
 
}